'use client'

import clsx from 'clsx'

import { ActiveLink } from 'components/navigation'


type Tab = {
  id: string
  title: Intl.Message
  isMain?: boolean
}

type TabProps = {
  className?: string
  title: Intl.Message
  to: string
}

const Tab: React.FC<TabProps> = (props) => {
  const { className, title, to } = props

  return (
    <ActiveLink
      className={clsx('tab sm:text-body-22', className)}
      activeClassName="active"
      message={title}
      to={to}
    />
  )
}

export type TabsProps = {
  className?: string
  tabs: Tab[]
  rootUrl: string
  tabClassName?: string
}

const TabsNav: React.FC<TabsProps> = (props) => {
  const { className, rootUrl, tabs, tabClassName } = props

  const rootClassName = clsx(
    'flex space-x-5 sm:space-x-8 mb-2 sm:mb-4 sm:px-10 px-6 border-b border-bg-20',
    'overflow-x-auto pt-5 sm:pt-6 no-scrollbar',
    className
  )

  return (
    <div className={rootClassName}>
      {
        tabs.map(({ id, title, isMain }) => (
          <Tab
            key={id}
            className={tabClassName}
            to={isMain ? `${rootUrl}` : `${rootUrl}/${id}`}
            title={title}
          />
        ))
      }
    </div>
  )
}

export default TabsNav
